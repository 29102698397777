
export default function () {

    const stripe = Stripe('pk_test_51PKiqNGqd9aTolhmrtvWiLO05fLpI8EYd0MgNXESKhh4qqcl4cITpTv5TqY9EuwLEHyUAnCVBjgYwUSkniE7TdXb00BgqG02OK');

    // Set up Stripe Elements
    const appearance = { /* appearance options */ };
    const options = { /* additional options */ };
    
    // Create elements
    const elements = stripe.elements({
        mode: 'payment',
        amount: 1099,
        currency: 'usd',
        appearance,
    });
    
    // Create and mount Express Checkout Element
    const expressCheckoutElement = elements.create('expressCheckout', options);
    expressCheckoutElement.mount('#express-checkout-element');
    
    // Create and mount Payment Element for credit card
    const paymentElement = elements.create('payment');
    paymentElement.mount('#payment-element');
    
    // Handle form submission
    const form = document.getElementById('payment-form');
    form.addEventListener('submit', async (event) => {
        event.preventDefault();
    
        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: 'https://your-domain.com/checkout-complete',
            },
        });
    
        if (error) {
            console.error(error.message);
        } else {
            console.log('Payment successful');
        }
    });

    const section = document.querySelector('body > main > section');
    const btnOpen = document.querySelector('.expand');
    const formContainer = document.querySelector('#payment-form');

    btnOpen.addEventListener('click', async () => {
        section.classList.add('card');
        formContainer.style.height = 'auto';
        formContainer.style.overflow = 'visible';
        btnOpen.style.display = 'none';
    });

}